import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
import { Tabs, Tab } from '@paljs/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Convert schema" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "pal-schema"
      }}>{`pal schema`}</h2>
      <p><strong parentName="p">{`Alias: `}</strong>{` `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal s`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal schema CONVERTER`}</code></pre></div>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`CONVERTER`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`json`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Convert your `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`schema.prisma`}</code>{` file into `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`JavaScript`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`TypeScript`}</code>{` and `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`Json`}</code>{` files`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`camel-case`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Convert your `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`schema.prisma`}</code>{` file from `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`Snake case`}</code>{` eg `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`first_name`}</code>{` to `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`Camel case`}</code>{` eg `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`firstName`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`typescript`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Convert your `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`schema.prisma`}</code>{` file into `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`TypeScript`}</code>{` definitions`}</td>
          </tr>
        </tbody>
      </table>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#convert-to-file"
          }}>{`Convert to file`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#convert-to-camel-case"
          }}>{`Convert to Camel case`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#convert-to-typescript-definitions"
          }}>{`Convert to TypeScript definitions`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#convertschematoobject"
          }}>{`ConvertSchemaToObject`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#schema-type"
          }}>{`Schema Type`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "convert-to-file"
      }}>{`Convert to file`}</h2>
      <p>{`Convert your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file into `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`JavaScript`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`TypeScript`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Json`}</code>{` files.`}</p>
      <p><strong parentName="p">{`options`}</strong></p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`char`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`flag`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-h`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--help`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`show CLI help`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-o`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--output-path=output-path`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`[default: prisma/]`}{` folder path for converted file`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-t`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--type=(js`}{`|`}{`ts`}{`|`}{`json)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`[default: ts]`}{` type of output file type when you convert to json`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-v`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--version`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`show CLI version`}</td>
          </tr>
        </tbody>
      </table>
      <p><strong parentName="p">{`Run`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`pal schema json`}</code></pre></div>
      <p><strong parentName="p">{`Example`}</strong></p>
      <Tabs mdxType="Tabs">
        <Tab title="schema.prisma" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "prisma"
          }}><pre parentName="div" {...{
              "className": "language-prisma"
            }}><code parentName="pre" {...{
                "className": "language-prisma"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`       `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  createdAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`  `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`    `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@unique`}</span>{`
  name     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`
  password `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// field comment`}</span>{`
  posts    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
  group    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Group`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`    `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`groupId`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  groupId  `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// field comment`}</span>{`
  comments `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Comment`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// some model comments`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// another model comments`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></code></pre></div>
        </Tab>
        <Tab title="schema.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` SchemaObject `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'@paljs/schema'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` schema`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` SchemaObject `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  models`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'id'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'createdAt'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'DateTime'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'email'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'String'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'name'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'String'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'password'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'String'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'posts'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Post'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'// field comment'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'group'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Group'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'groupId'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'comments'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Comment'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'// field comment'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'// some model comments\\n// another model comments'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  enums`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span></code></pre></div>
        </Tab>
        <Tab title="schema.js" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "js"
          }}><pre parentName="div" {...{
              "className": "language-js"
            }}><code parentName="pre" {...{
                "className": "language-js"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`default`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  models`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'id'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'createdAt'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'DateTime'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'email'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'String'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'name'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'String'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'password'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'String'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'posts'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Post'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'// field comment'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'group'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Group'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'groupId'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`''`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          name`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'comments'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          type`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Comment'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          isId`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          unique`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          list`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          required`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          kind`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'scalar'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'// field comment'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          relationField`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      documentation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'// some model comments\\n// another model comments'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  enums`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span></code></pre></div>
        </Tab>
        <Tab title="schema.json" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "json"
          }}><pre parentName="div" {...{
              "className": "language-json"
            }}><code parentName="pre" {...{
                "className": "language-json"
              }}><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"models"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"User"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"fields"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"id"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"Int"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"createdAt"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"DateTime"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"email"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"String"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"String"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"password"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"String"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"posts"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"Post"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"// field comment"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"group"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"Group"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"groupId"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"Int"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`""`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"name"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"comments"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"type"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"Comment"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"isId"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"unique"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"list"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`true`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"required"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"kind"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"scalar"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"// field comment"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
          `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"relationField"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span>{`
        `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"documentation"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"// some model comments\\n// another model comments"`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token property"
                }}>{`"enums"`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></code></pre></div>
        </Tab>
      </Tabs>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "convert-to-camel-case"
      }}>{`Convert to Camel case`}</h2>
      <p>{`Most of use after using `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Prisma Introspection`}</code>{`.`}</p>
      <p>{`Convert your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file from `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Snake case`}</code>{` to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Camel case`}</code>{`.`}</p>
      <p><strong parentName="p">{`options`}</strong></p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`char`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`flag`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-s`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--schema=schema`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`[default: prisma/schema.prisma]`}{` Add your schema.prisma file path`}</td>
          </tr>
        </tbody>
      </table>
      <p><strong parentName="p">{`Run`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`pal schema camel-case`}</code></pre></div>
      <p><strong parentName="p">{`Example`}</strong></p>
      <Tabs mdxType="Tabs">
        <Tab title="Snake case" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "prisma"
          }}><pre parentName="div" {...{
              "className": "language-prisma"
            }}><code parentName="pre" {...{
                "className": "language-prisma"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`datasource`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` db`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"sqlite"`}</span>{`
  url      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`env`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`generator`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` client`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id        `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`            `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  created_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`       `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  email     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`         `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@unique`}</span>{`
  first_name`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`
  password  `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(CASCADE)`}</span>{`
  posts     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
  group     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Group`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`         `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`group_id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  group_id  `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(SET_NULL)`}</span>{`
  comments   post_comment`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id        `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`            `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  published `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Boolean`}</span>{`        `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  title     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  author    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`          `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`author_id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  author_id `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(CASCADE)`}</span>{`
  comments   post_comment`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
  created_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`       `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  updated_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`       `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@updatedAt`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` post_comment`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id        `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  contain   `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  post      `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span>{`     `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`post_id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  post_id   `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`
  author    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`    `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`author_id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  author_id `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`
  created_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  updated_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@updatedAt`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Group`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id        `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  name      `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  created_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  updated_at`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@updatedAt`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(SET_NULL)`}</span>{`
  users     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></code></pre></div>
        </Tab>
        <Tab title="Camel case" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "prisma"
          }}><pre parentName="div" {...{
              "className": "language-prisma"
            }}><code parentName="pre" {...{
                "className": "language-prisma"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`datasource`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` db`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"sqlite"`}</span>{`
  url      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`env`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`generator`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` client`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`           `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  createdAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"created_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`        `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@unique`}</span>{`
  firstName`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`       `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"first_name"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  password `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(CASCADE)`}</span>{`
  posts    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
  group    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Group`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`        `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`groupId`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  groupId  `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`          `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"group_id"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(SET_NULL)`}</span>{`
  comments `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` PostComment`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`           `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  published`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Boolean`}</span>{`       `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token boolean"
                }}>{`false`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  title    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  author   `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`         `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  authorId `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`          `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"author_id"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(CASCADE)`}</span>{`
  comments `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` PostComment`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
  createdAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"created_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  updatedAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@updatedAt`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"updated_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` PostComment`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"post_comment"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  id       `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  contain  `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  post     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Post`}</span>{`     `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`postId`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  postId   `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"post_id"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  author   `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`    `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@relation`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`fields`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` references`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span>{`id`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  authorId `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`?`}</span>{`     `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"author_id"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  createdAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"created_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  updatedAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@updatedAt`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"updated_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`model`}</span><span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Group`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` Int`}</span>{`      `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`autoincrement`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@id`}</span>{`
  name     `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` String`}</span>{`
  createdAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@default`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`now`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"created_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  updatedAt`}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@updatedAt`}</span>{` `}<span parentName="code" {...{
                  "className": "token annotation"
                }}>{`@map`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`"updated_at"`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token comment"
                }}>{`// @onDelete(SET_NULL)`}</span>{`
  users    `}<span parentName="code" {...{
                  "className": "token class-name"
                }}>{` User`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`[`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`]`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></code></pre></div>
        </Tab>
      </Tabs>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "convert-to-typescript-definitions"
      }}>{`Convert to TypeScript definitions`}</h2>
      <p>{`Convert your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file into `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`TypeScript`}</code>{` definitions.`}</p>
      <p><strong parentName="p">{`options`}</strong></p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`char`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`flag`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`description`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-s`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--schema=schema`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`[default: prisma/schema.prisma]`}{` Add your schema.prisma file path`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`-o`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`--output-path=output-path`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`[default: src/]`}{` folder path for converted file`}</td>
          </tr>
        </tbody>
      </table>
      <p><strong parentName="p">{`Run`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`pal schema typescript`}</code></pre></div>
      <p><strong parentName="p">{`Example`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// schema.prisma`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`datasource`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` postgresql`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  url      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`env`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"postgresql"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`generator`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` client`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`cuid`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@unique`}</span>{`
  birthDate`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  role     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` UserRole`}</span>{`
  posts    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`cuid`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  author`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` UserRole`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`USER`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`ADMIN`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <h3 {...{
        "id": "output"
      }}>{`OutPut`}</h3>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token comment"
            }}>{`// schema.ts`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  email`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  birthDate`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Date `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`null`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  role`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` UserRole`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  posts`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Post`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  author`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` User`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span>{` UserRole `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`USER`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'USER'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`ADMIN`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'ADMIN'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "convertschematoobject"
      }}>{`ConvertSchemaToObject`}</h2>
      <p>{`Convert your current `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file in runtime`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` ConvertSchemaToObject `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/schema'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` schemaObject `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`ConvertSchemaToObject`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`path`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`run`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "schema-type"
      }}>{`Schema Type`}</h2>
      <p>{`You can import this types from our tool`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` SchemaObject`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` Model`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` Enums`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` Field `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/schema'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Model`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  documentation`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  map`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Field`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Enums`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Field`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  list`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  required`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  isId`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  unique`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  kind`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'object'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'enum'`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'scalar'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  map`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  relationField`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  documentation`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  relation`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`SchemaObject`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` models`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Model`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{` enums`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` Enums`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      